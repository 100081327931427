import dynamic from "next/dynamic";
import { Canvas } from "@react-three/fiber";

const DynamicBlob = dynamic(() => import("./Blob"),{
  loading: () => 'Loading...',
})

export default function Blobfold(props) {
	return(
		<>
			{/* Skills */}
      <section className="grid sm:grid-cols-2 gap-2 items-center grid-cols-1 z-10" 
          style={ {height: "90vh"} }>
          {/* Left */}
          <div> 
            {/* <h1>Hi!</h1> */}
            <h1>I&apos;m Aryan <i className="ri-admin-fill"></i></h1>
            <p className="text-3xl">Fullstack Web Developer</p>
            <p>I am a skilled full-stack web developer with three years of experience in creating, improving, and securing web applications. With proficiency in a range of web technologies, such as WordPress, React Frameworks, Cloud Hosting, and Web Security, and improved my skills in optimizing Core Web Vitals, ensuring exceptional user experience. </p>
            {/* <p>Proven track record of developing and deploying high-performing web applications that deliver outstanding results. Apart from development, I have also gained some knowledge of Marketing, Search Engine Optimization, and CRM Tools like Hubspot, Klaviyo, and MailerLite.</p> */}
          </div>
          {/* Right */}
          <div className="">
            <div className="blob-container">
              <Canvas camera={{ position: [0, 0, 8.0] }}>
                <DynamicBlob />
              </Canvas>
            </div>
          </div>
      </section>
		</>
	)
}